window.addEventListener('load', ()=>{
    readMore();
});

const blocks = document.querySelectorAll('.content-block');

function readMore() {
    blocks.forEach(block => {
        const wrapper = block.querySelector('.content-wrapper');
        const button = wrapper.querySelector('.js-read-more');

        button?.addEventListener('click', ()=>{
            wrapper.classList.remove('small');
            button.classList.add('hidden');
        })
    });
}